import React, { useEffect } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";

import RichText from "../components/RichText";

const Pravidla = ({ data }) => {
	return (
		<Layout>
			<div id="rules">
				<Helmet>
					<title>AppParade | Pravidla</title>
				</Helmet>
				<hr />
				<div className="container-heading">
					<h1 className="slash small-headline">Pravidla soutěže AppParade</h1>
				</div>
				<hr />
				<RichText content={data.rules.html} />
				<hr />
				<div className="container-heading">
					<h2 className="slash small-headline" id="zpracovani-osobnich-udaju">
						Souhlas se zpracováním osobních údajů
					</h2>
				</div>
				<hr />
				<RichText content={data.oou.html} />
			</div>
		</Layout>
	);
};

export default Pravidla;

Pravidla.propTypes = {
	data: PropTypes.shape({
		rules: PropTypes.shape({
			html: PropTypes.string.isRequired
		}).isRequired,
		oou: PropTypes.shape({
			html: PropTypes.string.isRequired
		}).isRequired
	}).isRequired
};

export const rulesQuery = graphql`
	{
		oou: markdownRemark(frontmatter: { title: { eq: "oou" } }) {
			html
		}
		rules: markdownRemark(frontmatter: { title: { eq: "rules" } }) {
			html
		}
	}
`;
