import React from "react";
import PropTypes from "prop-types";

const RichText = ({ content }) => (
	<div
		className="rich-text container font-flexa-light"
		dangerouslySetInnerHTML={{ __html: content }}
	/>
);

RichText.propTypes = {
	content: PropTypes.node.isRequired
};

export default RichText;
